<template>
	<!-- 按钮 -->
	<div class="d-flex justify-content-between mb-3">
		<button v-permit="'role.add'" type="button" class="btn btn-success me-3" @click="addClick()">
			<i class="ri-add-fill me-1 align-bottom"></i>
			{{ $t('role.add') }}
		</button>
	</div>
	
	<!-- 关键字搜索 -->
	<div class="row">
		<div class="col">
			<div class="search-box">
				<input type="text" class="form-control" :placeholder="$t('role.keyword_placeholder')" v-model="keyword" />
				<i class="ri-search-line search-icon"></i>
			</div>
		</div>
		<div class="col-auto">
			<button type="button" class="btn btn-primary me-3" @click="searchClick()">
				<i class="ri-search-line me-1 align-bottom"></i>
				{{ $t('base.search') }}
			</button>
			<button type="button" class="btn btn-info" @click="resetClick()">
				<i class="ri-reply-line me-1 align-bottom"></i>
				{{ $t('base.reset') }}
			</button>
		</div>
	</div>
	
</template>

<script>
	export default {
		emits: ['add', 'search'],
		data() {
			return {
				keyword: '',
			}
		},
		methods: {
			addClick() {
				this.$emit("add")
			},
			searchClick() {
				const params = {
					keyword: this.keyword
				}
				this.$emit("search", params)
			},
			resetClick() {
				this.keyword = ''
				this.searchClick()
			}
		}
	}
</script>

<style>
</style>