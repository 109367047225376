<template>
  <div class="form p-3" v-if="tableInfo.length">
    <!-- Bordered Tables -->
    <table class="table table-bordered table-nowrap">
      <thead class="table-light">
        <tr>
          <th scope="col" v-for="(head, index) in table_head" :key="index">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(info, index) in tableInfo"
          :key="index"
          style="vertical-align: middle"
        >
          <td>{{ info.name }}</td>
          <td>{{ info.description }}</td>
          <!-- <td class="time">{{ (info.last_time && moment(info.last_time).format('DD-MM-YYYY HH:mm:ss')) || '-' }}</td> -->
          <td class="time">{{ dateFormat1(info.last_time) || "-" }}</td>
          <td>
            <b-form-checkbox
              v-model="info.enabled"
              switch
              @change="switchChange(info)"
            ></b-form-checkbox>
          </td>
          <td v-if="showOperation()">
            <ul class="list-inline hstack gap-2 mb-0">
              <!-- 编辑 -->
              <li v-permit="'role.modify'" class="list-inline-item cursor">
                <div
                  class="text-primary d-inline-block"
                  @click="editClick(info)"
                >
                  <i class="fs-16 ri-pencil-fill"></i>
                </div>
              </li>
              <!-- 删除 -->
              <li
                v-permit="'role.delete'"
                class="list-inline-item"
                title="delete"
              >
                <div
                  class="d-inline-block text-danger cursor"
                  @click="deleteClick(info)"
                >
                  <i class="fs-16 ri-delete-bin-5-fill"></i>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <Empty v-else :flag="1"></Empty>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
</template>

<script>
import moment from "moment";
import { dateFormat1 } from "@/utils/common.js";
import Empty from "@/components/common/Empty";

export default {
  emits: ["operation"],
  props: {
    tableInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      table_head: [
        this.$t("attribute.role_name"),
        this.$t("attribute.description"),
        this.$t("attribute.last_time"),
        this.$t("attribute.status"),
        this.$t("base.operation"),
      ],
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      dateFormat1,
    };
  },
  created() {
    if (!this.showOperation()) {
      this.table_head.pop();
    }
  },
  methods: {
    moment,
    switchChange(info) {
      this.$emit("operation", "updateStatus", info);
    },
    editClick(info) {
      this.$emit("operation", "edit", info);
    },
    deleteClick(info) {
      this.$emit("operation", "delete", info);
    },
    showOperation() {
      const params = {
        list: ["role.modify", "role.delete"],
        type: "some",
      };
      return this.$permit(params);
    },
  },
  component: {
    Empty,
  },
};
</script>

<style scoped lang="scss"></style>
