<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <div class="block_content d-flex flex-column">
      <div class="card p-4 overflow-auto" style="max-height: 35%">
        <Search
          v-model:page="page"
          @add="addClick"
          @search="searchClick"
        ></Search>
      </div>
      <div class="card mb-0 flex-1 overflow-hidden">
        <div class="flex-1 overflow-auto">
          <Form :tableInfo="tableInfo" @operation="operationClick"></Form>
        </div>
        <!-- 分页 -->
        <Pagination
          v-if="tab_info_length"
          v-model:page="page"
          v-model:perPage="perPage"
          :pages="pages"
          :prePageOption="prePageOption"
          :tab_info_length="tab_info_length"
          class="me-5 my-3"
        ></Pagination>
      </div>
    </div>
    <Add
      v-if="isShow"
      :singleData="singleData"
      :formType="formType"
      v-model:isShowForm="isShow"
      @initRoleList="refreshData"
    ></Add>
    <message-alert
      :alertInfo="alertInfo"
      v-if="formValidataAlert"
    ></message-alert>
  </Layout>
</template>

<script>
import Search from "./Search";
import Form from "./Form";
import Add from "./Add";
import _ from "loadsh";
import { getRole, deleteRole, editRole } from "@/service";

import Swal from "sweetalert2";

export default {
  components: {
    Search,
    Form,
    Add,
  },
  data() {
    return {
      PageHeaderT: this.$t("role.title"),
      PageHeaderItems: [
        {
          text: this.$t("role.title"),
          active: true,
          href: "/role",
        },
      ],
      tableInfo: [],
      /* 分页 */
      tab_info_length: 0,
      page: 1,
      perPage: 10,
      prePageOption: [10, 20, 30, 40, 50],
      pages: [],
      isShow: false,
      formType: "add",
      singleData: null,
      formValidataAlert: false,
      form: {},
    };
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    operationClick(type, data) {
      if (type == "edit") {
        this.editData(data);
      } else if (type == "delete") {
        this.deleteData(data);
      } else if (type == "updateStatus") {
        this.updateStatus(data);
      }
    },
    addClick() {
      this.formType = "add";
      this.singleData = {
        p_name: null,
        name: "",
        description: "",
        disabled: true,
      };
      this.isShow = true;
    },
    searchClick(params) {
      this.form = params;
      this.requestRoles(params);
    },
    refreshData() {
      this.requestRoles({});
    },
    updateStatus(info) {
      let params = {
        id: info.id,
        name: info.name,
        description: info.description,
        disabled: !info.disabled,
      };
      editRole(params)
        .then((res) => {
          if (res) {
            this.refreshData();
          }
        })
        .catch((err) => {
          this.errorAlert(err.message);
          this.refreshData();
        });
    },
    editData(info) {
      this.formType = "edit";
      this.singleData = info;
      this.isShow = true;
    },
    deleteData(info) {
      if (info && info.users.length) {
        Swal.fire({
          title: this.$t("base.delete_alert_role_title"),
          text: this.$t("base.delete_alert_role_info"),
          icon: "warning",
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: "#34c38f",
          confirmButtonText: this.$t("base.cancel"),
        });
      } else {
        Swal.fire({
          title: this.$t("base.delete_alert_title"),
          text: this.$t("base.delete_alert_info"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          cancelButtonText: this.$t("base.cancel"),
          confirmButtonColor: "#34c38f",
          confirmButtonText: this.$t("base.done"),
        }).then((result) => {
          if (result.isConfirmed) {
            deleteRole(info.id)
              .then((res) => {
                if (res) {
                  let info = {
                    variant: "success",
                    content: this.$t("base.delete_alert_completed"),
                    icon: "ri-error-warning-line",
                  };
                  this.openAlert(info, "close");
                }
              })
              .then(() => {
                this.refreshData();
              })
              .catch((e) => {
                let info = {
                  variant: "danger",
                  content: e,
                  icon: "ri-error-warning-line",
                };
                this.openAlert(info);
              });
          }
        });
      }
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
        }, 2000);
      }
    },
    requestRoles(params) {
      params["limit"] = this.perPage;
      params["skip"] = (this.page - 1) * this.perPage;
      getRole(params)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            let data = _.cloneDeep(res.payload.data);
            data.forEach(function (item) {
              item.enabled = !item.disabled;
            });
            this.tableInfo = data;
            this.tab_info_length = res.payload.total;
            this.setPages();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 设置分页选项 */
    setPages() {
      this.pages = [];
      let numberOfPages = Math.ceil(this.tab_info_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
  },
  watch: {
    perPage(val) {
      if (val) {
        this.requestRoles(this.form);
      }
    },
    page(val) {
      if (val) {
        this.requestRoles(this.form);
      }
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
