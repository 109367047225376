<template>
	<div class="modal fade show" style="display: block">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header bg-light p-3">
					<h5 class="modal-title">{{ formTitle }}</h5>
					<button type="button" class="btn-close" @click="handleClose()"></button>
				</div>
				<form action="#" id="addform">
					<div class="modal-body">
						<div class="row gy-3 mb-1">
							<div>
								<label for="weight-field" class="form-label">
									<span class="text-danger">*</span>
									<span>{{ $t('attribute.role_name') }}</span>
								</label>
								<input
									type="text"
									class="form-control"
									v-model="form.name"
									:class="{
										'is-invalid': submitted && v$.form.name.$error
									}"
									required
								/>
								<div v-for="(item, index) in v$.form.name.$errors" :key="index" class="invalid-feedback">
									<span v-if="item.$message">{{ item.$message }}</span>
								</div>
							</div>
							<div>
								<label for="weight-field" class="form-label">{{ $t('attribute.description') }}</label>
								<b-form-textarea id="textarea" v-model="form.description" rows="3" max-rows="6"></b-form-textarea>
							</div>
							<!-- <div>
								<label for="weight-field" class="form-label">{{ $t('attribute.status') }}</label>
								<b-form-checkbox switch></b-form-checkbox>
							</div> -->
							<div class="d-flex">
								<span class="text-danger">*</span>
								<label for="weight-field" class="form-label">{{ $t('attribute.status') }}</label>
								<b-form-checkbox
									v-model="form.disabled"
									switch
									size="lg"
									style="margin-left: -30px; position: relative; transform: translateY(-70%);"
								></b-form-checkbox>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<div class="hstack gap-2 justify-content-end">
							<button type="button" class="btn btn-light" @click="handleClose()" :disabled="formValidataAlert">{{ $t('base.cancel') }}</button>
							<button type="button" class="btn btn-success" id="add-btn" @click="handleSubmit()" :disabled="formValidataAlert">{{ $t('base.done') }}</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="modal-backdrop fade show"></div>
	<message-alert :alertInfo="alertInfo" v-if="formValidataAlert" :showCloseBtn="true" v-model:formValidataAlert="formValidataAlert"></message-alert>
</template>
<script>
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import _ from 'loadsh';
import i18n from '@/i18n';

import { getRole, createRole, editRole } from '@/service/index';

export default {
	emits: ['update:isShowForm', 'initRoleList'],
	props: {
		isShowForm: Boolean,
		formType: {
			type: String,
			default: 'add'
		},
		singleData: {
			type: Object,
			default: () => {
				return {};
			}
		}
	},
	setup() {
		return { v$: useVuelidate() };
	},
	validations: {
		form: {
			name: {
				required: helpers.withMessage(i18n.global.t('role.hint_role_name'), required)
			}
		}
	},
	data() {
		return {
			form: {
				name: '',
				description: '',
				disabled: false
			},
			submitted: false,
			formValidataAlert: false,
			alertInfo: {
				variant: 'success',
				content: '',
				icon: 'ri-error-warning-line'
			},
			roles: []
		};
	},
	created() {
		this.requestRoles();
	},
	computed: {
		formTitle() {
			if (this.formType === 'edit') {
				return this.$t('role.edit')
			} else {
				return this.$t('role.add')
			}
		}
	},
	methods: {
		handleClose() {
			this.$emit('update:isShowForm', false);
		},
		handleSubmit() {
			this.submitted = true;
			this.v$.$touch();

			if (this.v$.$invalid) {
				return;
			} else {
				let params = {
					name: this.form.name,
					description: this.form.description,
					disabled: !this.form.disabled
				};
				if (this.formType == 'edit') {
					params['id'] = this.form.id;
					editRole(params)
						.then(res => {
							if (res) {
								let info = {
									variant: 'success',
									content: this.$t('base.edit_success'),
									icon: 'ri-error-warning-line'
								};
								this.openAlert(info, 'close');
								this.$emit('initRoleList');
							}
						})
						.catch(err => {
							let info = {
								variant: 'danger',
								content: err.message,
								icon: 'ri-error-warning-line'
							};
							this.openAlert(info);
						});
				} else {
					createRole(params)
						.then(res => {
							if (res) {
								if (res.code == 1) {
									this.errorAlert(res.message);
								} else {
									let info = {
										variant: 'success',
										content: this.$t('base.add_success'),
										icon: 'ri-error-warning-line'
									};
									this.openAlert(info, 'close');
									this.$emit('initRoleList');
								}
							}
						})
						.catch(err => {
							this.errorAlert(err.message);
						});
				}
			}
		},
		/* 消息提示框 */
		openAlert(info, close) {
			if (info) {
				this.alertInfo = info;
			}
			this.formValidataAlert = true;
			if (close) {
				setTimeout(() => {
					this.formValidataAlert = false;
					this.handleClose();
				}, 2000);
			}
		},
		errorAlert(message) {
			let info = {
				variant: 'danger',
				content: message,
				icon: 'ri-error-warning-line'
			};
			this.alertInfo = info;
			this.formValidataAlert = true;
			if (close) {
				setTimeout(() => {
					this.formValidataAlert = false;
				}, 2000);
			}
		},
		requestRoles() {
			const params = {
				only_parent_role: 1
			};
			getRole(params)
				.then(res => {
					if (res && res.payload && res.payload.data) {
						// this.roles = _.map(res.payload.data, 'name')
						this.roles = res.payload.data;
					}
				})
				.catch(err => {
					console.log(err);
				});
		}
	},
	watch: {
		singleData: {
			handler(val) {
				if (val) {
					this.form = _.cloneDeep(val);
					this.form.disabled = !this.form.disabled
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
